export function dateToISOString(date, appendTime = true) {
    if (date) {
        if (date instanceof Date){
            if(appendTime){
                return date.toISOString().substring(0, 19);
            } else{
                return date.toISOString().substring(0, 10);
            }
        } else {
            console.warn('dateToISOString expects a date, but was given ', date);
        }
    }
}

export function ISOStringToDate(dateString) {
    if (dateString) {
        if (typeof dateString === "string") {
            dateString = dateString.replace(' ', 'T');
            if (dateString.includes('T')) {
                dateString += dateString.includes('Z') ? '' : 'Z';
            }
            return new Date(dateString);
        } else {
            console.warn('ISOStringToDate expects a string, but was given ', date);
        }
    }
}

export function localDateToUTCDate(date) {
    if (date){
        if (date instanceof Date){
            return new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
        } else {
            console.warn('localDateToUTCDate expects a date, but was given ', date);
        }
    }
}

export function UTCDateToLocalDate(date) {
    if (date) {
        if (date instanceof Date){
            return new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        } else {
            console.warn('UTCDateToLocalDate expects a date, but was given ', date);
        }
    }
}

